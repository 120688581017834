import { FormControl, Validators } from "@angular/forms";
import { BaseControls } from "../../controls/base.controls";
import { AdvancedElementInterface } from "../../interfaces/advanced-element.interface";

export enum Keys {
  FirstName = "basic@firstName",
  LastName = "basic@lastName",
  Email = "basic@email",
  Phone = "basic@phone",
}

/**
 * Class for managing basic contact information form controls.
 *
 * @export
 * @class BasicContactInformationControls
 * @extends {BaseControls<AdvancedElementInterface>}
 */
export class BasicContactInformationControls extends BaseControls<AdvancedElementInterface> {
  /** @inheritdoc */
  protected register(component: AdvancedElementInterface): void {

    const fields = (component.fields ??= {
      FirstName: "",
      LastName: "",
      Email: "",
      Phone: "",
    }); 

    let componentSettings;

    if (component.componentSettings == null || component.componentSettings.disabledFields == null || component.componentSettings.disabledFields == undefined) {
      componentSettings = {
        disabledFields: {
          Email: false
        }
      };
    } else {
      componentSettings = component.componentSettings;
    }

    this.documentForm.addControl(
      Keys.FirstName,
      new FormControl(fields["FirstName"], Validators.required)
    );
    this.documentForm
      .get(Keys.FirstName)
      ?.valueChanges.subscribe(value => (fields["FirstName"] = value));

    this.documentForm.addControl(
      Keys.LastName,
      new FormControl(fields["LastName"], Validators.required)
    );
    this.documentForm
      .get(Keys.LastName)
      ?.valueChanges.subscribe(value => (fields["LastName"] = value));

    this.documentForm.addControl(
      Keys.Email,
      new FormControl({value:fields["Email"], disabled:componentSettings.disabledFields["Email"]}, [Validators.required, Validators.email])
    );

    this.documentForm
      .get(Keys.Email)
      ?.valueChanges.subscribe(value => (fields["Email"] = value));

    this.documentForm.addControl(
      Keys.Phone,
      new FormControl(fields["Phone"], [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(10),
        Validators.maxLength(10),
      ])
    );
    this.documentForm
      .get(Keys.Phone)
      ?.valueChanges.subscribe(value => (fields["Phone"] = value));
  }
}
