import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { BehaviorSubject, filter, fromEvent, map } from "rxjs";
import { FormDragAndDropInterface } from "../../common/shared-kernel";

@Component({
  selector: "fpt-preview",
  templateUrl: "./preview.component.html",
  styleUrls: ["./preview.component.scss"],
})
export class PreviewComponent {
  template$ = fromEvent<MessageEvent>(window, "message").pipe(
    filter(event => typeof event.data !== "object"),
    map(event => JSON.parse(event.data) as FormDragAndDropInterface)
  );

  documentForm?: FormGroup = new FormGroup({});
}
