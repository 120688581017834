import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Query,
  QueryList,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  fromEvent,
  map,
  of,
  switchMap,
  take,
} from "rxjs";
import { PendingService } from "./pending.service";
import { FormControl, FormGroup } from "@angular/forms";
import { StatusType } from "../../shared/status/status.component";
import { FormDragAndDropInterface } from "../../common/shared-kernel";
import { PdfService } from "../../services/pdf.service";
import { DocumentTemplateComponent } from "../../document-template/document-template.component";
import { IpService } from "src/app/services/ip.service";
import { Title } from "@angular/platform-browser";
import { DeviceService } from "src/app/services/device.service";

@Component({
  selector: "fpt-pending",
  templateUrl: "./pending.component.html",
  styleUrls: ["./pending.component.scss"],
})
export class PendingComponent implements OnInit {
  private key: string = "";
  document$ = new BehaviorSubject<FormDragAndDropInterface | undefined>(
    undefined
  );
  status$ = new BehaviorSubject<StatusType | undefined>(undefined);
  documentForm: FormGroup = new FormGroup({});
  ip!: string;
  loading: any = { 1: false, 2: false, 3: false, 4: false };
  isError = false;
  error = "";
  @ViewChild(DocumentTemplateComponent)
  documentTemplateForm!: DocumentTemplateComponent;

  constructor(
    private readonly pendingService: PendingService,
    private readonly pdfService: PdfService,
    private readonly route: ActivatedRoute,
    private readonly cd: ChangeDetectorRef,
    private ipService: IpService,
    private el: ElementRef,
    private title: Title,
    private deviceService: DeviceService
  ) {}

  ngOnInit(): void {
    this.title.setTitle("Fit Pro Tracker Digital Documents");

    // if (!this.deviceService.isValidDevice()) {
    //   this.status$.next("browser");
    //   return;
    // }

    this.key = this.route.snapshot.params["key"];

    if (!this.key) {
      return;
    }

    this.ipService.getIp().subscribe((data: any) => {
      this.ip = data.ip;
    });

    this.pendingService
      .getDocument(this.key)
      .pipe(
        catchError(() => {
          this.status$.next("error");
          return of();
        })
      )
      .subscribe(res => {
        if (res.status === "success") {
          this.document$.next(res.content);
          this.status$.next(undefined);
        } else {
          this.status$.next(res.status as unknown as StatusType);
        }
      });
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);

      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector(".ng-invalid");

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: "smooth",
    });

    fromEvent(window, "scroll")
      .pipe(debounceTime(100), take(1))
      .subscribe(() => firstInvalidControl.focus());
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  submit(i: number): void {
    // stop here if form is invalid
    if (!this.documentForm?.valid) {
      this.validateAllFormFields(this.documentForm);
      this.documentForm.markAllAsTouched();
      this.scrollToFirstInvalidControl();
      return;
    }

    this.loading[i] = true;
    this.isError = false;
    this.error = "";

    this.pendingService
      .submit(this.key, {
        content: JSON.stringify(this.document$.value, null, 2),
        pdfFileBase64: "", // remove the base64 string
      })
      .pipe(
        catchError(error => {
          this.error = error.error && error.error.exceptionMessage ? error.error.exceptionMessage : "";
          this.loading[i] = false;
          this.isError = true;
          this.cd.detectChanges();
          return of();
        })
      )
      .subscribe(() => {
        this.loading[i] = false;
        this.status$.next("submitted");
        this.document$.next(undefined);
        this.cd.detectChanges();
      });

    // this.pdfService
    //   .convertHtmlToPdfBase64(
    //     this.documentTemplateForm.getFormElement(),
    //     this.ip
    //   )
    //   .pipe(
    //     switchMap(base64 => {
    //       return this.pendingService.submit(this.key, {
    //         content: JSON.stringify(this.document$.value, null, 2),
    //         pdfFileBase64: base64,
    //       });
    //     }),
    //     catchError(error => {
    //       this.loading[i] = false;
    //       this.isError = true;
    //       this.cd.detectChanges();
    //       return of();
    //     })
    //   )
    //   .subscribe(() => {
    //     this.loading[i] = false;
    //     this.status$.next("submitted");
    //     this.document$.next(undefined);
    //     this.cd.detectChanges();
    //   });
  }
}
