import { Directive } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import {
  ElementInterface,
  MultipleSelectInterface,
  OptionInterface,
} from "../interfaces";
import { BaseControls } from "./base.controls";

/**
 * MultiSelectControls class.
 *
 * @export
 * @class MultiSelectControls
 * @extends {BaseControls<ElementInterface>}
 */
@Directive()
export class MultiSelectControls extends BaseControls<ElementInterface> {
  /** @inheritdoc */

  protected register(component: ElementInterface): void {
    const element = component.element as MultipleSelectInterface;
    if (!element.field_name) {
      throw new Error("Field name is required.");
    }

    this.documentForm.addControl(
      element.field_name,
      new FormControl(
        this.findSelectedValue(element.options),
        element.is_required ? Validators.required : []
      )
    );

    this.documentForm.get(element.field_name)?.valueChanges.subscribe(value => {
      this.selectOption(element.options, value);
    });
  }

  /**
   * Changes the "selected" state in option.
   *
   * @private
   * @param {OptionInterface[]} options Options.
   * @param {string} value Value to be selected.
   * @memberof MultiSelectControls
   */
  private selectOption(options: OptionInterface[], value: string): void {
    options.forEach(o => {
      o.is_selected = o.value === value;
    });
  }

  /**
   * Finds the selected value.
   *
   * @private
   * @param {OptionInterface[]} options Options.
   * @return {string} Selected value.
   * @memberof MultiSelectControls
   */
  private findSelectedValue(options: OptionInterface[]): string {
    return options.find(o => o.is_selected)?.value ?? "";
  }
}
