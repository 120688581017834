<div *ngIf="documentForm" [formGroup]="documentForm">
  <h5>Contact Address</h5>
  <div class="row">
    <div class="input-group mb-3" ngbDropdown #myDrop="ngbDropdown">
      <select class="form-select" style="max-width: 20ch" [formControlName]="ControlKeys.HelperCountryCode">
        <option *ngFor="let country of countries" [ngValue]="country.code">
          {{ country.name }}
        </option>
      </select>
      <input type="text" class="form-control" (click)="myDrop.open();" [formControlName]="ControlKeys.HelperQuery" ngbDropdownToggle placeholder="Search your address here..." />
      <div ngbDropdownMenu aria-labelledby="dropdownManual">
        <button *ngFor="let address of suggestedAddresses" (click)="select(address)" ngbDropdownItem>
          {{ address.address.freeformAddress }}
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <div style="padding: 10px 0px; border-radius: 0px; height: auto">
        <label class="m-0" style="color: rgb(0, 0, 0); font-size: 14px">
          <strong class="d-inline-block">Street 1 *</strong>
        </label>
        <input type="text" class="form-control" placeholder="Street 1" style="
            background-color: rgb(255, 255, 255);
            border: 1px solid rgb(206, 212, 218);
            border-radius: 5px;
          " [formControlName]="ControlKeys.Street1" />

        <app-error [field]="f[ControlKeys.Street1]" requiredMessage="Street 1">
        </app-error>
      </div>
    </div>

    <div class="col-lg-6 col-md-6 col-sm-12">
      <div style="padding: 10px 0px; border-radius: 0px; height: auto">
        <label class="m-0" style="color: rgb(0, 0, 0); font-size: 14px">
          <strong class="d-inline-block">Street 2</strong>
        </label>
        <input type="text" class="form-control" placeholder="Street 2" style="
            background-color: rgb(255, 255, 255);
            border: 1px solid rgb(206, 212, 218);
            border-radius: 5px;
          " [formControlName]="ControlKeys.Street2" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <div style="padding: 10px 0px; border-radius: 0px; height: auto">
        <label class="m-0" style="color: rgb(0, 0, 0); font-size: 14px">
          <strong class="d-inline-block">City *</strong>
        </label>
        <input type="text" class="form-control" placeholder="City" style="
            background-color: rgb(255, 255, 255);
            border: 1px solid rgb(206, 212, 218);
            border-radius: 5px;
          " [formControlName]="ControlKeys.City" />
        <app-error [field]="f[ControlKeys.City]" requiredMessage="City">
        </app-error>
      </div>
    </div>

    <div class="col-lg-6 col-md-6 col-sm-12">
      <div style="padding: 10px 0px; border-radius: 0px; height: auto">
        <label class="m-0" style="color: rgb(0, 0, 0); font-size: 14px">
          <strong class="d-inline-block">State/Province/Region *</strong>
        </label>
        <select class="form-select" [formControlName]="ControlKeys.Subdivision">
          <option value="" disabled selected>
            Select Your State/Province/Region
          </option>
          <option *ngFor="let stateOrProvince of getCurrentStatesOrProvinces()" [ngValue]="stateOrProvince.code">
            {{ stateOrProvince.name }}
          </option>
        </select>
        <app-error [field]="f[ControlKeys.Subdivision]" requiredMessage="State/Province">
        </app-error>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <div style="padding: 10px 0px; border-radius: 0px; height: auto">
        <label class="m-0" style="color: rgb(0, 0, 0); font-size: 14px">
          <strong class="d-inline-block">Zip/Postal Code</strong>
        </label>
        <input type="text" class="form-control" style="
            background-color: rgb(255, 255, 255);
            border: 1px solid rgb(206, 212, 218);
            border-radius: 5px;
          " [formControlName]="ControlKeys.ZipCode" />
      </div>
    </div>

    <div class="col-lg-6 col-md-6 col-sm-12">
      <div style="padding: 10px 0px; border-radius: 0px; height: auto">
        <label class="m-0" style="color: rgb(0, 0, 0); font-size: 14px">
          <strong class="d-inline-block">Country *</strong>
        </label>
        <select class="form-select" [formControlName]="ControlKeys.CountryCode">
          <option *ngFor="let country of countries" [ngValue]="country.code">
            {{ country.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</div>