import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, of } from "rxjs";
import { environment } from "../../../environments/environment";
import { FormDragAndDropInterface } from "../../common/shared-kernel";

type SubmitDocumentRequest = {
  content: string;
  pdfFileBase64: string;
};

@Injectable({
  providedIn: "root",
})
export class EvergreenService {
  private readonly baseUrl = `${environment.fptApiUrl}/digital/public`;

  constructor(private readonly httpClient: HttpClient) {}

  getDocumentTemplate(key: string): Observable<FormDragAndDropInterface> {
    // TODO: Create "DigitalPublic" client and move this functionality into the client.
    return this.httpClient
      .get<string>(`${this.baseUrl}/document/e/${key}/template`)
      .pipe(map(value => JSON.parse(value) as FormDragAndDropInterface));
  }

  submit(key: string, request: SubmitDocumentRequest): Observable<Object> {
    return this.httpClient.post(
      `${this.baseUrl}/document/e/${key}/submit`,
      request
    );
  }
}
