import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  forwardRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { CustomDateParserFormatter } from "./formatter/custom-date-parser.formatter";

const DEFAULT_NGB_DATE = new NgbDate(0, 0, 0);

@Component({
  selector: "fpt-datepicker",
  templateUrl: "./datepicker.component.html",
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
  ],
})
export class DatepickerComponent implements OnInit, ControlValueAccessor {
  @Input()
  placeholder!: string;

  @Input()
  isforBirthday!: boolean;

  @Output()
  selectDate = new EventEmitter<string>();

  @Input()
  date!: string;

  model = DEFAULT_NGB_DATE;

  today: NgbDate = this.calendar.getToday();

  faCalendar = faCalendar;

  clientAvgAge = this.calendar.getPrev(this.calendar.getToday(), "y", 40);
  minPickerDate = this.calendar.getPrev(this.calendar.getToday(), "y", 90);
  maxPickerDate = this.calendar.getPrev(this.calendar.getToday(), "y", 0);

  constructor(private readonly calendar: NgbCalendar) {}

  ngOnInit(): void {
    if (!this.date) {
      this.model = DEFAULT_NGB_DATE;
    } else {
      const [year, month, day] = this.date.split("-").map(Number);
      this.model = new NgbDate(year, month, day);
    }

    if (!this.isforBirthday){
      this.maxPickerDate = this.calendar.getNext(this.calendar.getToday(), "y", 90); 
    }
  }

  select(ngbDate: NgbDate): void {
    this.executeSelectEvent(ngbDate);
  }

  selectToday(): void {
    this.model = this.today;
    this.executeSelectEvent(this.model);
  }

  clear(): void {
    this.model = DEFAULT_NGB_DATE;
    this.executeSelectEvent(this.model);
  }

  executeSelectEvent(ngbDate: NgbDate): void {
    this.selectDate.emit(
      DEFAULT_NGB_DATE.equals(ngbDate)
        ? ""
        : `${ngbDate.year}-${ngbDate.month}-${ngbDate.day}`
    );
  }

  onChange = (value: any) => {};
  onTouch = () => {};

  writeValue(value: any): void {
    if (value) {
      const [year, month, day] = value.split("-").map(Number);
      this.model = new NgbDate(year, month, day);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
