<div *ngIf="documentForm" [formGroup]="documentForm">
  <h5>Contact Information</h5>


  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <div style="padding: 10px 0px; border-radius: 0px; height: auto">
        <label class="m-0" style="color: rgb(0, 0, 0); font-size: 14px">
          <strong class="d-inline-block">First Name *</strong>
        </label>
        <input type="text" class="form-control" placeholder="First Name" style="
          background-color: rgb(255, 255, 255);
          border: 1px solid rgb(206, 212, 218);
          border-radius: 5px;" [formControlName]="ControlKeys.FirstName" />
        <app-error [field]="f[ControlKeys.FirstName]" requiredMessage="First Name">
        </app-error>
      </div>
    </div>

    <div class="col-lg-6 col-md-6 col-sm-12">
      <div style="padding: 10px 0px; border-radius: 0px; height: auto">
        <label class="m-0" style="color: rgb(0, 0, 0); font-size: 14px">
          <strong class="d-inline-block">Last Name *</strong>
        </label>
        <input type="text" class="form-control" placeholder="Last Name" style="
            background-color: rgb(255, 255, 255);
            border: 1px solid rgb(206, 212, 218);
            border-radius: 5px;
          " [formControlName]="ControlKeys.LastName" />
        <app-error [field]="f[ControlKeys.LastName]" requiredMessage="Last Name">
        </app-error>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <div style="padding: 10px 0px; border-radius: 0px; height: auto">
        <label class="m-0" style="color: rgb(0, 0, 0); font-size: 14px">
          <strong class="d-inline-block">Email *</strong>
        </label>
        <input type="text" class="form-control" placeholder="Email" style="
            background-color: rgb(255, 255, 255);
            border: 1px solid rgb(206, 212, 218);
            border-radius: 5px;
          " [formControlName]="ControlKeys.Email" />
        <app-error [field]="f[ControlKeys.Email]" requiredMessage="Email" [isEmail]="true">
        </app-error>
      </div>
    </div>

    <div class="col-lg-6 col-md-6 col-sm-12">
      <div style="padding: 10px 0px; border-radius: 0px; height: auto">
        <label class="m-0" style="color: rgb(0, 0, 0); font-size: 14px">
          <strong class="d-inline-block">Phone *</strong>
        </label>
        <input type="text" class="form-control" (keypress)="keyPress($event)" placeholder="Phone" style=" 
            background-color: rgb(255, 255, 255);
            border: 1px solid rgb(206, 212, 218);
            border-radius: 5px;
          " [formControlName]="ControlKeys.Phone" />
        <app-error [field]="f[ControlKeys.Phone]" requiredMessage="Phone Number" [isPhone]="true">
        </app-error>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <div style="padding: 10px 0px; border-radius: 0px; height: auto">
        <label class="m-0" style="color: rgb(0, 0, 0); font-size: 14px">
          <strong class="d-inline-block">Gender</strong>
        </label>
        <select class="form-select" [formControlName]="ControlKeys.Gender">
          <option value="" selected disabled>Select Your Gender</option>
          <option *ngFor="let item of genders" [ngValue]="item.code">
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-lg-6 col-md-6 col-sm-12">
      <div style="padding: 10px 0px; border-radius: 0px; height: auto">
        <label class="m-0" style="color: rgb(0, 0, 0); font-size: 14px">
          <strong class="d-inline-block">Birthday *</strong>
        </label>
        <fpt-datepicker placeholder="mm / dd / yyyy" [date]="component.fields?.['BirthDate'] ?? ''" 
        [isforBirthday]="true" (selectDate)="selectDate($event)">
        </fpt-datepicker>
        <app-error [field]="f[ControlKeys.BirthDate]" requiredMessage="BirthDate">
        </app-error>
      </div>
    </div>
  </div>
</div>