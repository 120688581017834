import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CountryCode } from "../constants";

export type AddressInformation = {
  streetNumber: string;
  countryCode: string;
  countrySubdivision: string;
  freeformAddress: string;
  postalCode?: string;
  streetName: string;
  localName: string;
};
export type Address = {
  address: AddressInformation;
  position: {
    lat: number;
    lon: number;
  };
};
export type GetAddressResponse = {
  results: Address[];
};

@Injectable({
  providedIn: "root",
})
export class ContactAddressService {
  private readonly subscriptionKey =
    "LwU5_RbeFNXByudVymYprP20lQX2MRS0htayrnd0CFM";

  private readonly addressGeocodeServiceUrlTemplate =
    "https://atlas.microsoft.com/search/address/json?typeahead=true&api-version=1&view=Auto";

  constructor(private readonly httpClient: HttpClient) {}

  getAddresses(
    countryCode: CountryCode,
    query: string
  ): Observable<GetAddressResponse> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });

    return this.httpClient.get<GetAddressResponse>(
      this.addressGeocodeServiceUrlTemplate,
      {
        headers,
        params: {
          query,
          countrySet: countryCode,
          "subscription-key": this.subscriptionKey,
          language: "en-US",
        },
      }
    );
  }
}
