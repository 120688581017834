import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PendingComponent } from "./pending.component";
import { DocumentTemplateModule } from "../../document-template/document-template.module";
import { ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../../shared/shared.module";
import { AdiButtonsModule } from "src/app/tools/buttons/buttons.module";
import { LoadersModule } from "src/app/tools/loaders/loaders.module";
import { DeviceDetectorService } from "ngx-device-detector";

@NgModule({
  declarations: [PendingComponent],
  imports: [
    CommonModule,
    SharedModule,
    DocumentTemplateModule,
    ReactiveFormsModule,
    AdiButtonsModule,
    LoadersModule,
  ],
  providers : [DeviceDetectorService]
})
export class PendingModule {}
