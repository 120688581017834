import { FormControl, Validators } from "@angular/forms";
import { BaseControls } from "../../controls/base.controls";
import { SignatureInterface } from "../../interfaces/signature.interface";

export class SignatureControls extends BaseControls<SignatureInterface> {
  protected register(component: SignatureInterface): void {
    this.documentForm.addControl(
      component.field_name,
      new FormControl(
        component.value || "",
        component.is_required ? Validators.required : null
      )
    );
    this.documentForm
      .get(component.field_name)
      ?.valueChanges.subscribe(value => (component.value = value));
  }
}
