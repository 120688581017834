<div *ngIf="field.touched && field.invalid" class="error-msg">
    <div *ngIf="field.errors?.['required']">{{requiredMessage}} is required.</div>

    <div *ngIf="isPhone">
        <div *ngIf="field.errors?.['pattern']  || field.errors?.['maxlength'] || field.errors?.['minlength']">Phone number must be at least 10 numbers.</div>
    </div>

    <div *ngIf="field.errors?.['email']">Please format the email address.</div>


</div>