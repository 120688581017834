import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { FormGroup, NgForm } from "@angular/forms";

@Component({
  selector: "fpt-document-template",
  templateUrl: "./document-template.component.html",
})
export class DocumentTemplateComponent {
  @Input()
  templateStructure: any[] = [];

  @Input()
  formDesign: any;

  @ViewChild("form")
  form!: ElementRef<HTMLElement>;

  getFormElement(): HTMLElement {
    return this.form.nativeElement;
  }
}
