import { NgModule } from "@angular/core";
import { BrowserModule, Title } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PendingModule } from "./documents/pending/pending.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { HttpClientModule } from "@angular/common/http";
import { EvergreenModule } from "./documents/evergreen/evergreen.module";
import { PreviewModule } from "./documents/preview/preview.module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    PendingModule,
    EvergreenModule,
    PreviewModule,
    FontAwesomeModule,
    HttpClientModule,
  ],
  providers: [Title],
  bootstrap: [AppComponent],
})
export class AppModule {}
