import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-social",
  templateUrl: "./social.component.html",
  styleUrls: ["./social.component.scss"],
})
export class SocialComponent implements OnInit {
  @Input() component: any;

  constructor() {}

  ngOnInit() {}
}
