import { Component, Input, OnInit } from "@angular/core";
import { FormArray, FormControl } from "@angular/forms";
import { MultiControls } from "./multi.controls";

@Component({
  selector: "app-multi",
  templateUrl: "./multi.component.html",
  styleUrls: ["./multi.component.scss"],
})
export class MultiComponent extends MultiControls implements OnInit {
  @Input() component: any;
  @Input() formDesign: any;

  ngOnInit() {
    this.register(this.component);
    // this.addCheckboxes();
  }

  get multiFormArray() {
    return this.component.element.options as FormArray;
  }

  private addCheckboxes() {
    this.component.element.options.forEach(() =>
      this.multiFormArray.push(new FormControl(false))
    );
  }

  get name() {
    return this.documentForm.get(this.component.element.field_name);
  }

  updateOption(option: any) {
    option.is_selected = !option.is_selected;
  }
}
