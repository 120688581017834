import { Injectable } from "@angular/core";
import html2pdf from "html2pdf.js";
import { from, map, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PdfService {
  private readonly options = {
    margin: 0.5,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    pagebreak: { mode: ["avoid-all", "css", "legacy"] },
  };

  private readonly opt = {
    margin: [20, 5, 12.5, 5], // top, left, bottom, right - adauga cate 5 la fiecare daca e nevoie
    filename: "antoniotest.pdf",
    image: {
      type: "jpeg", // jpg, jpeg, bmp, png
      quality: 1.0,
    },
    html2canvas: {
      scale: 1.5,
      dpi: 192,
      letterRendering: true,
      windowWidth: "280mm",
      windowHeight: "260mm",
      allowTaint: true,
    },
    jsPDF: {
      unit: "mm",
      // format: "a4",
      format: [260, 280],
      orientation: "landscape", // landscape sau portrait
      compress: true,
    },
    pagebreak: {
      mode: "css",
      // mode: ["avoid-all", "css", "legacy"],
      before: ".break-before",
      after: ".break-after",
      avoid: ".break-avoid",
    },
  };

  convertHtmlToPdfBase64(element: HTMLElement, ip: string): Observable<string> {
    return from(
      html2pdf()
        .from(element)
        .set(this.opt)
        .toPdf()
        .get("pdf")
        .then((pdf: any) => {
          var totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(100);
            pdf.text(`Submit Date: ${new Date()}`, 0.5, 10.75);
            pdf.text(`Page ${i} of ${totalPages}`, 7.0, 10.75);
            if (ip) {
              pdf.text(`IP address: ${ip}`, 0.5, 10.9);
            }
          }
        })
        // .save()
        .outputPdf() as Promise<string>
    ).pipe(map(value => btoa(value)));
  }
}
