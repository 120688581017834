import { Component, Input, OnInit } from "@angular/core";
import { AdvancedElementInterface } from "../../interfaces/advanced-element.interface";
import {
  STATES,
  PROVINCES,
  COUNTRIES,
  DEFAULT_COUNTRY_CODE,
  CountryCode,
} from "./constants";
import { ContactAddressControls, Keys } from "./contact-address.controls";
import { CountrySubdivision } from "./types";

@Component({
  selector: "fpt-contact-address",
  templateUrl: "./contact-address.component.html",
  styleUrls: ["./contact-address.component.scss"],
})
export class ContactAddressComponent
  extends ContactAddressControls
  implements OnInit
{
  @Input()
  component!: AdvancedElementInterface;
  readonly countries = COUNTRIES;

  get ControlKeys() {
    return Keys;
  }

  ngOnInit(): void {
    this.register(this.component);
  }

  public getCurrentStatesOrProvinces(): CountrySubdivision[] {
    const code: CountryCode =
      this.documentForm?.get(Keys.CountryCode)?.value ?? DEFAULT_COUNTRY_CODE;

    return this.getStatesOrProvinces(code);
  }

  private getStatesOrProvinces(code: CountryCode): CountrySubdivision[] {
    return code === "US" ? STATES : PROVINCES;
  }

  get f() {
    return this.documentForm.controls;
  }
}
