import { Component, Input, OnInit } from "@angular/core";
import { ElementInterface } from "../../interfaces";
import { InputInterface } from "../../interfaces/input.interface";
import { InputControls } from "./input.controls";

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
})
export class InputComponent extends InputControls implements OnInit {
  @Input() component!: ElementInterface;
  @Input() formDesign: any;

  get element() {
    return this.component.element as InputInterface;
  }

  ngOnInit() {
    this.register(this.component);
  }

  get name() {
    return this.documentForm.get(this.element.field_name);
  }

  get form() {
    return this.documentForm;
  }
}
