import { Component, Input, OnInit } from "@angular/core";
import { AdvancedElementInterface } from "../../interfaces/advanced-element.interface";
import { BaseAdvancedComponent } from "../base-advanced.component";
import { EmergencyContactControls, Keys } from "./emergency-contact.controls";

@Component({
  selector: "fpt-emergency-contact",
  templateUrl: "./emergency-contact.component.html",
  styleUrls: ["./emergency-contact.component.scss"],
})
export class EmergencyContactComponent
  extends EmergencyContactControls
  implements OnInit
{
  @Input()
  component!: AdvancedElementInterface;

  get ControlKeys() {
    return Keys;
  }

  ngOnInit(): void {
    this.register(this.component);
  }

  get f() {
    return this.documentForm.controls;
  }

  //only number will be add
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
