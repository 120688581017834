<div class="input-group">
  <input type="text" class="form-control" name="dp" autocomplete="off" ngbDatepicker #d="ngbDatepicker" [footerTemplate]="footerTemplate" [placeholder]="placeholder" [(ngModel)]="model" (dateSelect)="select($event)" [minDate]="minPickerDate" [maxDate]="maxPickerDate" readonly />
  <button class="btn btn-secondary" (click)="d.toggle();d.navigateTo(today)" type="button">
    <fa-icon class="icon" [icon]="faCalendar"></fa-icon>
  </button>
</div>

<ng-template #footerTemplate>
  <hr class="my-0" />
  <button class="btn btn-secondary btn-sm m-2 float-start" (click)="selectToday()">
    Today
  </button>
  <button class="btn btn-danger btn-sm m-2 float-end" (click)="clear()">
    Clear
  </button>
</ng-template>