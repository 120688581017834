import { Directive } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { BaseControls } from "../../controls/base.controls";
import { ElementInterface, CheckboxInterface } from "../../interfaces";

/**
 * CheckboxControls class.
 *
 * @export
 * @class CheckboxControls
 * @extends {BaseControls<ElementInterface>}
 */
@Directive()
export class CheckboxControls extends BaseControls<ElementInterface> {
  /** @inheritdoc */
  protected register(component: ElementInterface): void {
    const element = component.element as CheckboxInterface;
    if (!element.field_name) {
      throw new Error("Field name is required.");
    }

    this.documentForm.addControl(
      element.field_name ?? "",
      new FormControl(
        element.selected,
        element.is_required ? Validators.required : []
      )
    );

    this.documentForm
      .get(element.field_name)
      ?.valueChanges.subscribe(
        (selected: boolean) => (element.selected = selected)
      );
  }
}
