import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
  selector: "fpt-error-message",
  templateUrl: "./error.message.html",
})
export class ErrorMessageComponent implements OnChanges {
  @Input()
  error: string = "";
  errorMessage: string = "";

  constructor() {
    this.setMessage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["error"]) {
      this.setMessage();
    }
  }

  setMessage() {
    if(this.error.includes("Unknown contact group")){
      this.errorMessage = "This document can't be submitted. Reason: Invalid Contact Group. By contacting your staff, you should be able to resolve it quickly.";
    }else{
      this.errorMessage = "We seem to have run into an error in submitting your document. Please reach out to the contact who sent the form for further assistance.";
    }
  }
}
