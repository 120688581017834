import { Directive } from "@angular/core";
import { FormGroupDirective } from "@angular/forms";

/**
 * BaseControls class.
 *
 * @export
 * @class BaseControls
 * @implements {FormControls<TComponent>}
 */
@Directive()
export abstract class BaseControls<TComponent> {
  /**
   * Parent document form group.
   *
   * @memberof BaseControls
   */
  documentForm = this.parentForm.form;

  /**
   * Creates an instance of BaseControls.
   *
   * @param {FormGroupDirective} parentForm Directive of parent form group.
   * @memberof BaseControls
   */
  constructor(private readonly parentForm: FormGroupDirective) {}

  /**
   * Registers the controls for @see TComponent element.
   *
   * @param {TComponent} component Component based on which creates the controls.
   * @memberof FormControls
   */
  protected abstract register(component: TComponent): void;
}
