import { Component, Input } from "@angular/core";
import {
  faInfoCircle,
  faFaceSmile,
  faHand,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";

export type StatusType = "error" | "revoked" | "submitted" | "browser";
type Message = {
  icon: string;
  title: string;
  content: string;
};
type Messages = { [T in StatusType]: Message };

const MESSAGES: Messages = {
  error: {
    icon: "https://res.cloudinary.com/fitpromaster/image/upload/v1672849874/fpt/404.png",
    title: "Whoops, there is something wrong with this link",
    content: "Please reach out to your staff and try again!",
  },
  submitted: {
    icon: "https://res.cloudinary.com/fitpromaster/image/upload/v1672850487/fpt/submit.png",
    title: "Thanks for your response!",
    content:
      "Please reach out to your staff if you want a copy of this document",
  },
  revoked: {
    icon: "https://res.cloudinary.com/fitpromaster/image/upload/v1672850542/fpt/revoked.png",
    title: "Whoops!, this link has been revoked",
    content: "Please reach out to your staff and try again!",
  },
  browser: {
    icon: "https://res.cloudinary.com/fitpromaster/image/upload/v1672850542/fpt/revoked.png",
    title: "Whoops!, This browser is not supported by the system",
    content: "Please try it on a desktop device",
  },
};

@Component({
  selector: "fpt-status",
  templateUrl: "./status.component.html",
  styleUrls: ["./status.component.scss"],
})
export class StatusComponent {
  @Input()
  type: StatusType = "error";

  get messageIcon(): string {
    return MESSAGES[this.type].icon;
  }

  get messageTitle(): string {
    return MESSAGES[this.type].title;
  }

  get messageContent(): string {
    return MESSAGES[this.type].content;
  }
}
