import { FormGroup } from "@angular/forms";
import { Component, OnInit, Input, Output } from "@angular/core";

@Component({
  selector: "app-error",
  templateUrl: "./error.html",
  styleUrls: ["./error.scss"],
})
export class ErrorDisplayComponent {
  @Input()
  field!: any;

  @Input()
  requiredMessage!: string;

  @Input()
  isPhone = false;

  @Input()
  isEmail = false;
}
