import { Component, Input, OnInit } from "@angular/core";
import { FormGroupDirective } from "@angular/forms";
import { MultiSelectControls } from "../../controls/multi-select.controls";
import { ElementInterface, MultipleSelectInterface } from "../../interfaces";

@Component({
  selector: "app-dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.scss"],
})
export class DropdownComponent extends MultiSelectControls implements OnInit {
  @Input() component!: ElementInterface;
  @Input() formDesign: any;

  get element() {
    return this.component.element as MultipleSelectInterface;
  }

  ngOnInit() {
    this.register(this.component);
  }
}
