import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnInit,
} from "@angular/core";
import SignaturePad from "signature_pad";
import { ElementInterface } from "../../interfaces";
import { SignatureInterface } from "../../interfaces/signature.interface";
import { SignatureControls } from "./signature.controls";

@Component({
  selector: "fpt-signature",
  templateUrl: "./signature.component.html",
  styleUrls: ["./signature.component.scss"],
})
export class SignatureComponent
  extends SignatureControls
  implements AfterViewInit, OnInit
{
  @Input()
  component!: ElementInterface;

  @ViewChild("canvas")
  canvasEl!: ElementRef;

  get element() {
    return this.component.element as SignatureInterface;
  }

  get name() {
    return this.documentForm.get(this.element.field_name);
  }

  signaturePad!: SignaturePad;

  ngOnInit(): void {
    this.register(this.element);
  }

  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);

    if (this.element.value) {
      this.signaturePad.fromDataURL(this.element.value);
    }

    this.resizeCanvas();
  }

  save(): void {
    if (this.signaturePad.isEmpty()) {
      return;
    }

    const signatureBase64 = this.signaturePad.toDataURL("image/png");
    this.documentForm.get(this.element.field_name)?.setValue(signatureBase64);
  }

  clear(): void {
    this.signaturePad.clear();
    this.documentForm.get(this.element.field_name)?.setValue("");
  }

  resizeCanvas(): void {
    const canvas = this.canvasEl.nativeElement;
    const ratio = Math.max(window.devicePixelRatio || 1, 1);

    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);

    this.signaturePad.fromData(this.signaturePad.toData());
  }
}
