import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PreviewComponent } from "./preview.component";
import { DocumentTemplateModule } from "../../document-template/document-template.module";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [PreviewComponent],
  imports: [CommonModule, DocumentTemplateModule, ReactiveFormsModule],
})
export class PreviewModule {}
