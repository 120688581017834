import { Component, OnInit, Input } from "@angular/core";
import { FormGroupDirective } from "@angular/forms";
import { ElementInterface } from "../../interfaces";
import { CheckboxInterface } from "../../interfaces/checkbox.interface";
import { CheckboxControls } from "./checkbox.controls";

@Component({
  selector: "app-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.scss"],
})
export class CheckboxComponent extends CheckboxControls implements OnInit {
  @Input()
  component!: ElementInterface;

  get element(): CheckboxInterface {
    return this.component.element as CheckboxInterface;
  }

  @Input() formDesign: any;

  ngOnInit() {
    this.register(this.component);
  }

  get name() {
    return this.documentForm.get(this.element.field_name);
  }
}
