import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EvergreenComponent } from "./evergreen.component";
import { DocumentTemplateModule } from "../../document-template/document-template.module";
import { ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../../shared/shared.module";
import { AdiButtonsModule } from "../../tools/buttons/buttons.module";
import { LoadersModule } from "../../tools/loaders/loaders.module";
import { DeviceDetectorService } from "ngx-device-detector";

@NgModule({
  declarations: [EvergreenComponent],
  imports: [
    CommonModule,
    DocumentTemplateModule,
    ReactiveFormsModule,
    SharedModule,
    AdiButtonsModule,
    LoadersModule,
  ],
  providers : [DeviceDetectorService]
})
export class EvergreenModule {}
