<div
  [ngStyle]="{
    'padding-top': component.componentSettings.padding_top + 'px',
    'padding-right': component.componentSettings.padding_right + 'px',
    'padding-bottom': component.componentSettings.padding_bottom + 'px',
    'padding-left': component.componentSettings.padding_left + 'px',
    'background-color': component.componentSettings.background_color,
    'border-radius': component.componentSettings.rounded_corners + 'px',
    height:
      component.componentSettings.height > 0
        ? component.componentSettings.height + 'px'
        : 'auto'
  }"
>
  <hr
    class="hr-form-builder"
    [ngStyle]="{
      'border-top-style': component.element.border_style,
      'border-top-width': component.element.border_width + 'px',
      'border-top-color': component.element.border_color,
      margin: 0
    }"
  />
</div>
