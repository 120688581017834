import { Injectable } from "@angular/core";
import { DeviceDetectorService, OS } from 'ngx-device-detector';

@Injectable({
  providedIn: "root",
})
export class DeviceService {
  constructor(private deviceDetectionService : DeviceDetectorService) {}

  isValidDevice() {
    if(!this.deviceDetectionService.isMobile())
        return true;
    return this.deviceDetectionService.os !== OS.IOS
  }
}
