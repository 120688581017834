import { Directive } from "@angular/core";
import { Validators, FormControl, FormGroupDirective } from "@angular/forms";
import { debounceTime, filter, switchMap, map } from "rxjs";
import { BaseControls } from "../../controls/base.controls";
import { AdvancedElementInterface } from "../../interfaces/advanced-element.interface";
import { DEFAULT_COUNTRY_CODE } from "./constants";
import {
  Address,
  ContactAddressService,
} from "./services/contact-address.service";

export enum Keys {
  HelperCountryCode = "address@helper@countryCode",
  HelperQuery = "address@helper@query",
  Street1 = "address@street1",
  Street2 = "address@street2",
  City = "address@city",
  Subdivision = "address@subdivision",
  ZipCode = "address@zipCode",
  CountryCode = "address@countryCode",
  Payload = "address@payload",
}

const DEFAULT_FIELDS = {
  Street1: "",
  Street2: "",
  City: "",
  ZipCode: "",
  State: "",
  CountryCode: DEFAULT_COUNTRY_CODE,
  Payload: "",
};

/**
 * ContactAddressControls class.
 *
 * @export
 * @class ContactAddressControls
 * @extends {BaseControls<AdvancedElementInterface>}
 */
@Directive()
export class ContactAddressControls extends BaseControls<AdvancedElementInterface> {
  /**
   * List of suggested addresses based on query.
   *
   * @type {Address[]}
   * @memberof ContactAddressControls
   */
  suggestedAddresses: Address[] = [];

  /**
   * Creates an instance of ContactAddressControls.
   *
   * @param {ContactAddressService} contactAddressService Address management service.
   * @param {FormGroupDirective} parentForm Directive of parent form group.
   * @memberof ContactAddressControls
   */
  constructor(
    private readonly contactAddressService: ContactAddressService,
    parentForm: FormGroupDirective
  ) {
    super(parentForm);
  }

  /** @inheritdoc */
  protected register(component: AdvancedElementInterface): void {
    const fields = (component.fields = {
      ...DEFAULT_FIELDS,
      ...component.fields,
    });

    this.documentForm.addControl(
      Keys.HelperCountryCode,
      new FormControl(DEFAULT_COUNTRY_CODE)
    );

    this.documentForm
      .get(Keys.HelperCountryCode)
      ?.valueChanges.subscribe(value => {
        this.documentForm.get(Keys.CountryCode)?.setValue(value);
      });

    this.documentForm.addControl(Keys.HelperQuery, new FormControl(""));

    this.documentForm
      .get(Keys.HelperQuery)
      ?.valueChanges.pipe(
        debounceTime(400),
        filter(Boolean),
        switchMap(query =>
          this.contactAddressService.getAddresses(
            this.documentForm.get(Keys.HelperCountryCode)?.value,
            query
          )
        ),
        map(res => res.results)
      )
      .subscribe(item => (this.suggestedAddresses = item));

    this.documentForm.addControl(
      Keys.Street1,
      new FormControl(fields["Street1"], Validators.required)
    );

    this.documentForm
      .get(Keys.Street1)
      ?.valueChanges.subscribe(value => (fields["Street1"] = value));

    this.documentForm.addControl(
      Keys.Street2,
      new FormControl(fields["Street2"])
    );

    this.documentForm
      .get(Keys.Street2)
      ?.valueChanges.subscribe(value => (fields["Street2"] = value));

    this.documentForm.addControl(
      Keys.City,
      new FormControl(fields["City"], Validators.required)
    );

    this.documentForm
      .get(Keys.City)
      ?.valueChanges.subscribe(value => (fields["City"] = value));

    this.documentForm.addControl(
      Keys.Subdivision,
      new FormControl(fields["State"], Validators.required)
    );

    this.documentForm
      .get(Keys.Subdivision)
      ?.valueChanges.subscribe(value => (fields["State"] = value));

    this.documentForm.addControl(
      Keys.ZipCode,
      new FormControl(fields["ZipCode"])
    );

    this.documentForm
      .get(Keys.ZipCode)
      ?.valueChanges.subscribe(value => (fields["ZipCode"] = value));

    this.documentForm.addControl(
      Keys.CountryCode,
      new FormControl(fields["CountryCode"], Validators.required)
    );

    this.documentForm.get(Keys.CountryCode)?.valueChanges.subscribe(value => {
      fields["CountryCode"] = value;
      this.documentForm.get(Keys.Subdivision)?.setValue("");
      this.documentForm
        .get(Keys.HelperCountryCode)
        ?.setValue(value, { emitEvent: false });
    });

    this.documentForm.addControl(
      Keys.Payload,
      new FormControl(fields["Payload"])
    );
    this.documentForm
      .get(Keys.Payload)
      ?.valueChanges.subscribe(value => (fields["Payload"] = value));
  }

  select(address: Address): void {
    this.documentForm?.patchValue({
      [Keys.HelperQuery]: null,
      [Keys.Street1]:
        address.address.streetNumber !== null &&
        address.address.streetNumber !== undefined
          ? address.address.streetNumber + " " + address.address.streetName
          : address.address.streetName,
      [Keys.City]: address.address.localName,
      [Keys.ZipCode]: address.address.postalCode ?? "",
      [Keys.CountryCode]: address.address.countryCode,
      [Keys.Subdivision]: address.address.countrySubdivision,
      [Keys.Payload]: JSON.stringify(address),
    });
  }
}
