<ng-template #recursiveList let-elements>
  <div *ngFor="let component of elements" class="selected-component">

    <ng-container [ngSwitch]="component.machineName">

      <ng-container *ngTemplateOutlet="sectionTpl; context: { $implicit: component }"></ng-container>

      <app-text *ngSwitchCase="'text'" [component]="component"></app-text>

      <app-picture *ngSwitchCase="'picture'" [component]="component"></app-picture>

      <app-button *ngSwitchCase="'button'" [component]="component"></app-button>

      <app-textarea *ngSwitchCase="'textarea'" [component]="component" [formDesign]="formDesign"></app-textarea>

      <app-spacer *ngSwitchCase="'spacer'" [component]="component"></app-spacer>

      <app-separator *ngSwitchCase="'separator'" [component]="component"></app-separator>

      <app-social *ngSwitchCase="'social'" [component]="component"></app-social>

      <app-checkbox *ngSwitchCase="'checkbox'" [component]="component" [formDesign]="formDesign"></app-checkbox>

      <app-dropdown *ngSwitchCase="'dropdown'" [component]="component" [formDesign]="formDesign"></app-dropdown>

      <app-radios *ngSwitchCase="'radios'" [component]="component" [formDesign]="formDesign"></app-radios>

      <app-multi *ngSwitchCase="'multi'" [component]="component" [formDesign]="formDesign"></app-multi> 

      <app-input *ngSwitchCase="'input'" [component]="component" [formDesign]="formDesign"></app-input>

      <!-- <app-uploader *ngSwitchCase="'uploader'" [component]="component" [formDesign]="formDesign"></app-uploader> -->

      <fpt-signature *ngSwitchCase="'signature'" [component]="component"></fpt-signature>

      <fpt-basic-contact-information *ngSwitchCase="'basic-contact-information'" [component]="component"></fpt-basic-contact-information>

      <fpt-advanced-contact-information *ngSwitchCase="'advanced-contact-information'" [component]="component"></fpt-advanced-contact-information>

      <fpt-contact-address *ngSwitchCase="'contact-address'" [component]="component"></fpt-contact-address>

      <fpt-emergency-contact *ngSwitchCase="'emergency-contact'" [component]="component"></fpt-emergency-contact>

    </ng-container>
  </div>
</ng-template>

<ng-template #sectionTpl let-component>
  <div class="row no-gutters" [ngSwitch]="component.column">

    <ng-container *ngSwitchCase="'2-1-3'">
      <div class="col-4 border border-primary border-dashed section-area" [ngClass]="{'not-added-component': component.addedComponents[0].length === 0}">
        <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: component.addedComponents[0] }"></ng-container>
      </div>

      <div class="col-8 border border-primary border-dashed section-area" [ngClass]="{ 'not-added-component': component.addedComponents[1].length === 0 }">
        <ng-container *ngTemplateOutlet=" recursiveList; context: { $implicit: component.addedComponents[1] } "></ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'2-3-1'">
      <div class="col-8 border border-primary border-dashed section-area" [ngClass]="{
          'not-added-component': component.addedComponents[0].length === 0
        }">
        <ng-container *ngTemplateOutlet="
            recursiveList;
            context: { $implicit: component.addedComponents[0] }
          ">
        </ng-container>
      </div>
      <div class="col-4 border border-primary border-dashed section-area" [ngClass]="{
          'not-added-component': component.addedComponents[1].length === 0
        }">
        <ng-container *ngTemplateOutlet="
            recursiveList;
            context: { $implicit: component.addedComponents[1] }
          ">
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div *ngFor="let c of component.addedComponents; let i = index" class="col border border-primary border-dashed section-area" [ngClass]="{
          'not-added-component': component.addedComponents[i].length === 0
        }">
        <ng-container *ngTemplateOutlet="
            recursiveList;
            context: { $implicit: component.addedComponents[i] }
          ">
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>

<div #form class="form-container mx-auto" [ngStyle]="{
    'max-width': formDesign.fullWidth ? '100%' : formDesign.width + 'px',
    'background-color': formDesign.container_background_color,
    'border-radius': formDesign.rounded_corners + 'px',
    padding: formDesign.form_paddings + 'px',
    border: 'solid ' + formDesign.form_border_width + 'px ' + formDesign.form_border_color }">

  <ng-container *ngTemplateOutlet=" recursiveList; context: {$implicit: templateStructure}"></ng-container>
</div>