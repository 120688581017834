import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { FormDragAndDropInterface } from "../../common/shared-kernel";
import { StatusType } from "../../shared/status/status.component";

export type GetDocumentStatusType = StatusType | "success";

type SubmitDocumentRequest = {
  content: string;
  pdfFileBase64: string;
};

type GetDocumentResponse<TContent> = {
  status: GetDocumentStatusType;
  content?: TContent;
};

@Injectable({
  providedIn: "root",
})
export class PendingService {
  private readonly baseUrl = `${environment.fptApiUrl}/digital/public`;

  constructor(private readonly httpClient: HttpClient) {}

  getDocument(
    key: string
  ): Observable<GetDocumentResponse<FormDragAndDropInterface>> {
    // TODO: Create "DigitalPublic" client and move this functionality into the client.
    return this.httpClient
      .get<GetDocumentResponse<string>>(
        `${this.baseUrl}/document/b/${key}/document`
      )
      .pipe(
        map(({ status, content }) => ({
          status,
          content: content
            ? (JSON.parse(content) as FormDragAndDropInterface)
            : undefined,
        }))
      );
  }

  submit(key: string, request: SubmitDocumentRequest): Observable<Object> {
    return this.httpClient.post(
      `${this.baseUrl}/document/b/${key}/submit`,
      request
    );
  }
}
