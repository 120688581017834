<div *ngIf="template$ | async as template">
  <div
    class="d-flex flex-column gap-3"
    style="
      min-height: 100vh;
      padding-top: 56px;
      padding-bottom: 10px;
      background-color: rgb(245, 245, 245);
    "
    *ngIf="documentForm"
    [formGroup]="documentForm"
  >
    <fpt-document-template
      [templateStructure]="template.added_components"
      [formDesign]="template.form_design"
    >
    </fpt-document-template>
  </div>
</div>
