export type CountryCode = typeof COUNTRIES[number]["code"];
export const DEFAULT_COUNTRY_CODE: CountryCode = "US";
export const COUNTRIES = [
  {
    name: "United States",
    code: "US",
  },
  {
    name: "Canada",
    code: "CA",
  },
] as const;
