import { Directive } from "@angular/core";
import { FormArray, FormControl, Validators } from "@angular/forms";
import { BaseControls } from "../../controls/base.controls";
import {
  ElementInterface,
  MultipleSelectInterface,
  OptionInterface,
} from "../../interfaces";

@Directive()
export class MultiControls extends BaseControls<ElementInterface> {
  protected register(component: ElementInterface): void {
    const element = component.element as MultipleSelectInterface;

    if (!element.field_name) {
      throw new Error("Field name is required.");
    }

    this.documentForm.addControl(
      element.field_name,
      new FormControl(
        this.findSelectedValue(element.options),
        element.is_required ? Validators.required : []
      )
    );

    // this.documentForm.addControl(
    //   element.field_name,
    //   new FormControl(
    //     element.value,
    //     Validators.compose([
    //       element.is_required ? Validators.required : null,
    //       component.type === "email" ? Validators.email : null,
    //     ])
    //   )
    // );

    this.documentForm.get(element.field_name)?.valueChanges.subscribe(value => {
      this.atLeastOneChecked(element.options);
    });
  }

  private atLeastOneChecked(options: OptionInterface[]) {
    if (options.filter(x => x.is_selected === true).length === 0) {
      return true;
    }
    return false;
  }

  private selectOption(options: OptionInterface[], value: string): void {
    options.forEach(o => {
      o.is_selected = o.value === value;
    });
  }

  private findSelectedValue(options: OptionInterface[]): string {
    var x = options.find(o => o.is_selected)?.value ?? "";
    return x;
  }
}
