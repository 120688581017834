import { Directive } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { BaseControls } from "../../controls/base.controls";
import { ElementInterface } from "../../interfaces";
import { InputInterface } from "../../interfaces/input.interface";

/**
 * InputControls class.
 *
 * @export
 * @class InputControls
 * @extends {BaseControls<ElementInterface>}
 */
@Directive()
export class InputControls extends BaseControls<ElementInterface> {
  /** @inheritdoc */
  protected register(component: ElementInterface): void {
    const element = component.element as InputInterface;
    if (!element.field_name) {
      throw new Error("Field name is required.");
    }

    this.documentForm.addControl(
      element.field_name,
      new FormControl(
        element.value,
        Validators.compose([
          element.is_required ? Validators.required : null,
          component.type === "email" ? Validators.email : null,
        ])
      )
    );

    this.documentForm
      .get(element.field_name)
      ?.valueChanges.subscribe(value => (element.value = value));
  }
}
