<div class="row" (window:resize)="resizeCanvas()" [formGroup]="documentForm">
  <div class="container">
    <label class="label">
      {{ element.label }} {{ element.is_required ? "*" : "" }}
    </label>

    <div class="signature-wrapper">
      <button class="clear" (click)="clear()">Clear</button>
      <canvas #canvas class="canvas" (mouseleave)="save()" (touchend)="save()"></canvas>
    </div>
    <app-error *ngIf="element.is_required" [field]="name" requiredMessage="Signature">
    </app-error>
  </div>
</div>