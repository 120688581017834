import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonComponent } from "./elements/button/button.component";
import { DropdownComponent } from "./elements/dropdown/dropdown.component";
import { InputComponent } from "./elements/input/input.component";
import { SignatureComponent } from "./elements/signature/signature.component";
import { PictureComponent } from "./elements/picture/picture.component";
import { TextComponent } from "./elements/text/text.component";
import { SpacerComponent } from "./elements/spacer/spacer.component";
import { DatepickerComponent } from "./elements/input/datepicker/datepicker.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AdvancedContactInformationComponent } from "./elements/advanced-contact-information/advanced-contact-information.component";
import { BasicContactInformationComponent } from "./elements/basic-contact-information/basic-contact-information.component";
import { EmergencyContactComponent } from "./elements/emergency-contact/emergency-contact.component";
import { ContactAddressComponent } from "./elements/contact-address/contact-address.component";
import { TextareaComponent } from "./elements/textarea/textarea.component";
import { SeparatorComponent } from "./elements/separator/separator.component";
import { SocialComponent } from "./elements/social/social.component";
import { CheckboxComponent } from "./elements/checkbox/checkbox.component";
import { RadiosComponent } from "./elements/radios/radios.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { StatusModule } from "./status/status.module";
import { ErrorDisplayComponent } from "./field-error-display/error.component";
import { MultiComponent } from "./elements/multicheckbox/multi.component";
import { ErrorMessageComponent } from "./messages/error.message.component";

@NgModule({
  declarations: [
    ButtonComponent,
    DropdownComponent,
    InputComponent,
    SignatureComponent,
    PictureComponent,
    TextComponent,
    SpacerComponent,
    DatepickerComponent,
    TextareaComponent,
    SeparatorComponent,
    SocialComponent,
    CheckboxComponent,
    RadiosComponent,
    MultiComponent,
    BasicContactInformationComponent,
    AdvancedContactInformationComponent,
    EmergencyContactComponent,
    ContactAddressComponent,
    ErrorDisplayComponent,
    ErrorMessageComponent,
  ],
  exports: [
    ButtonComponent,
    DropdownComponent,
    InputComponent,
    SignatureComponent,
    PictureComponent,
    TextComponent,
    SpacerComponent,
    DatepickerComponent,
    TextareaComponent,
    SeparatorComponent,
    SocialComponent,
    CheckboxComponent,
    RadiosComponent,
    MultiComponent,
    BasicContactInformationComponent,
    AdvancedContactInformationComponent,
    EmergencyContactComponent,
    ContactAddressComponent,
    StatusModule,
    ErrorMessageComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    StatusModule,
  ],
})
export class SharedModule {}
