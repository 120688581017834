import { Component, Input, OnInit } from "@angular/core";
import { FormGroupDirective } from "@angular/forms";
import { MultiSelectControls } from "../../controls/multi-select.controls";

@Component({
  selector: "app-radios",
  templateUrl: "./radios.component.html",
  styleUrls: ["./radios.component.scss"],
})
export class RadiosComponent extends MultiSelectControls implements OnInit {
  @Input() component: any;
  @Input() formDesign: any;

  ngOnInit() {
    this.register(this.component);
  }
}
