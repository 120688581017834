import { Component, Input, OnInit } from "@angular/core";
import { ElementInterface } from "../../interfaces";
import { InputInterface } from "../../interfaces/input.interface";
import { TextareaControls } from "./textarea.controls";

@Component({
  selector: "app-textarea",
  templateUrl: "./textarea.component.html",
  styleUrls: ["./textarea.component.scss"],
})
export class TextareaComponent extends TextareaControls implements OnInit {
  @Input()
  component!: ElementInterface;

  @Input()
  formDesign: any;

  get element() {
    return this.component.element as InputInterface;
  }

  ngOnInit() {
    this.register(this.component);
  }
}
