import { AfterViewInit, Component, Input, ViewChild } from "@angular/core";

@Component({
  selector: "app-text",
  templateUrl: "./text.component.html",
  styleUrls: ["./text.component.scss"],
})
export class TextComponent implements AfterViewInit {
  @Input()
  component: any;

  @ViewChild("content")
  content!: any;

  ngAfterViewInit() {
    this.content.nativeElement.innerHTML = this.component.element.content;
  }
}
