<div>
  <div *ngIf="document$ | async as document">

    <form *ngIf="documentForm && document" [formGroup]="documentForm" class="d-flex flex-column gap-3" style="min-height: 100vh;padding-top: 56px;padding-bottom: 10px;background-color: rgb(245, 245, 245);">

      <fpt-document-template [templateStructure]="document.added_components" [formDesign]="document.form_design"></fpt-document-template>

      <fpt-error-message *ngIf="isError" [error]="error"></fpt-error-message>

      <button *ngIf="!isPreview" adiButton [loading]="loading[2]" variant="primary" class="btn btn-success mx-auto submit" type="button" (click)="submit(2)">Submit
        <ng-template adiButtonLoader> Submitting Document... </ng-template>
      </button>
    </form>
  </div>

  <div *ngIf="status$ | async as status">
    <fpt-status [type]="status"></fpt-status>
  </div>
</div>